<template>
	<div
		v-if="bannerVisible"
		class="desktop div-banner-text"
		:style="{
			'font-size': bannerContent.desktop.fontsize + 'px',
			'background-color': bannerContent.desktop.bgcolor,
			color: bannerContent.desktop.textcolor,
		}"
	>
		<p
			id="sitewide-banner-text-desktop"
			class="t-white mb-0 t-center p-2"
			v-html="compiledDesktopText"
		></p>
	</div>
	<div
		v-if="bannerVisible"
		class="mobile"
		:style="{
			'font-size': bannerContent.mobile.fontsize + 'px',
			'background-color': bannerContent.mobile.bgcolor,
			color: bannerContent.mobile.textcolor,
		}"
	>
		<p
			id="sitewide-banner-text-mobile"
			class="t-white mb-0 t-center p-2"
			v-html="compiledMobileText"
		></p>
	</div>
</template>

<script>
import { marked } from 'marked'
import * as fs from 'fs'
import * as yaml from 'js-yaml'
import cookieHandler from '../utils/cookies/cookieHandler.js'
import { mapGetters } from 'vuex'
export default {
	name: 'SitewideBanner',
	data() {
		return {
			sectiondata: {},
			sitewideActive: true,
			accountActive: true,
		}
	},
	computed: {
		...mapGetters('user', ['isOnline']),
		bannerContent() {
			if (Object.keys(this.sectiondata).length === 0) return null
			return this.isInAccount ? this.sectiondata.account : this.sectiondata.sitewide
		},
		compiledMobileText() {
			return marked.parse(this.bannerContent?.mobile.text) || ''
		},
		compiledDesktopText() {
			return marked.parse(this.bannerContent?.desktop.text) || ''
		},
		bannerVisible() {
			if (this.isInAccount)
				return (
					this.bannerContent &&
					this.bannerContent.toggle &&
					(this.bannerContent.ispermanent || this.accountActive)
				)
			else
				return (
					this.bannerContent &&
					this.bannerContent.toggle &&
					(this.bannerContent.ispermanent || this.sitewideActive)
				)
		},
		isInAccount() {
			return (
				this.isOnline &&
				(window.location.pathname.includes('/account') ||
					window.location.pathname.includes('/login'))
			)
		},
	},
	watch: {
		isOnline() {
			if (cookieHandler.getCookie('sitewide-banner')) this.sitewideActive = false
			if (cookieHandler.getCookie('account-banner')) this.accountActive = false
			if (this.isInAccount) cookieHandler.setCookie('account-banner', false, '15d')
			else cookieHandler.setCookie('sitewide-banner', false, '15d')

			setTimeout(function () {
				//const banner = document.getElementById('sitewide-banner')
				if (this.bannerVisible){
        	var hero = document.getElementById('hero-banner')
					// if(hero){
					//   hero.classList.add('sitewide-adjust')
					// }
				}
			}.bind(this), 500)
		},
	},
	mounted() {
		try {
			const fileContents = fs.readFileSync(
				'_data/sitewidebanner/sitewide-banner.yml',
				'utf8'
			)
			const data = yaml.safeLoad(fileContents)
			this.sectiondata = data
		} catch (e) {
			console.log('error fething cms info', e)
		}

		if (cookieHandler.getCookie('sitewide-banner')) this.sitewideActive = false
		if (cookieHandler.getCookie('account-banner')) this.accountActive = false
		if (this.isInAccount) cookieHandler.setCookie('account-banner', false, '15d')
		else cookieHandler.setCookie('sitewide-banner', false, '15d')

		setTimeout(function () {
			//const banner = document.getElementById('sitewide-banner')
			if (this.bannerVisible){
        var hero = document.getElementById('hero-banner')
        // if(hero){
        //   hero.classList.add('sitewide-adjust')
        // }
      }
		}.bind(this), 500)
	},
}
</script>
<style lang="scss">
#sitewide-banner-text-desktop,
#sitewide-banner-text-mobile {
	p {
		margin-bottom: 0px !important;
		a {
			color: white;
			text-decoration: underline;
		}
	}
}
</style>
